@font-face {
    font-family: 'CustomFont';
    src: url('./font.ttf') format('truetype');
  }


  @font-face {
    font-family: 'Mona';
    src: url('./Mona.ttf') format('truetype');
  }

.heading {
  font-size: 37px;
  font-family: 'CustomFont', sans-serif;
  }

  .stat {
    font-size: 15px;
    font-family: 'Mona', sans-serif;
    margin-top: -42px;
  }


.green {
    color: green;
  }

  .light-green {
    color: rgb(37, 190, 37);
    font-size: 17px;
    font-family: CustomFont;
  }
    

.minutes-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .minute {
    width: 11px;
    height: 11px;
    background-color: rgba(211, 211, 211, 0.452);
    margin: 1px;
    border-radius: 1px;
  }
  
  .minute.working {
    background-color: green;
  }

  .hover-container .lifetime {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    color:  rgb(77, 77, 77);

  }
  
  .hover-container:hover .lifetime {
    opacity: 1;
    visibility: visible;

  }

  .work-button {
    font-family: 'Mona', sans-serif;
    padding: 5px 10px;
    font-size: 0.8em;
    color: rgb(40, 190, 40);
    background-color: transparent;
    border: 1px solid #008000;
    border-radius: 3px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    margin-top: 50px;
  }

  .reset-button {
    font-family: 'Mona', sans-serif;
    padding: 5px 10px;
    font-size: 0.8em;
    color: rgb(40, 190, 40);
    background-color: transparent;
    border: 1px solid #008000;
    border-radius: 3px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    margin-top: 13px;
  }
  

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.7;
    }
    70% {
      transform: scale(1);
      opacity: 0;
    }
    100% {
      transform: scale(0.95);
      opacity: 0.7;
    }
  }
  
  .blinking-dot {
    height: 10px;
    width: 10px;
    background-color: rgb(5, 179, 5);
    display: inline-block;
    animation: pulse 3s ease-in-out infinite;
    margin-right: 2px;
  }
    .overlay-control {
      position: absolute;
      z-index: 10;
      padding: 20px;
    }
  
    .icon-button {
      padding: 20px;
      border: none;
      bottom: 0;
      margin: 0;
      cursor: pointer;
      outline: none;
  
      i {
        font-size: 32px;
      }
    }
  
  
    button {
      background-color: transparent;
      border: 2px solid rgba(255,255,255,0.8);
      padding: 10px 15px;
      margin: 25px 10px;
    }
  
  
  
    .info-menu-container {
      bottom: 0;
      right: 0;
      color: #777;
      font-size: 14px;
      font-weight: 300;
      overflow: visible;
    }
  
  #info-menu {
    display: block;
    white-space: nowrap;
    list-style-type: none;
    position: absolute;
    bottom: 8px;
    right: 0;
    opacity: 0;
    transition: opacity .3s ease, transform .3s ease;
    
    li {
      display: inline-block;
    }
    
    a {
      color: rgb(77, 77, 77);

      padding: 5px;
  
      &:hover {
        text-decoration: none;
      }
    }
    
    &.is-visible {
      opacity: 1;
      transform: translateX(-40px);
    }
  }
  
  .button-menu {
    right: 0;
    padding: 5px 0 0 5px;
    border: none;
    bottom: 0;
    margin: 0;
    cursor: pointer;
    outline: none;
  
    .menu-icon {
      width: 13px;
      height: 9px;
      position: relative;
      
      .bar {
        background-color: rgb(77, 77, 77);
        position: absolute;
        left: 0;
        height: 1px;
        transition: left .3s ease;
      }
      .bar-top {
        top: 0;
        width: 100%;
      }
      .bar-middle {
        top: 4px;
        width: 60%;
      }
      .bar-bottom {
        bottom: 0;
        width: 30%;
      }
    }
  
    &.is-active {
      .bar-middle {
        left: 40%;
      }
      
      .bar-bottom {
        left: 70%;
      }
    }
  }

  .info-div {
    font-family: CustomFont;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  @media (max-width: 600px) {
    #info-menu {
      font-family: Mona;
      position: static;
      opacity: 1;
      transform: none;
      margin-top: 20px;
      padding-left: 10px;
      font-size: 12px;
    }
  
    .info-menu-container {
      position: static;
      padding-bottom: 20px;
    }

    .button-menu {
      display: none;
    }
  }

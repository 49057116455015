html, body {
  margin: 0;
  padding: 0;
  }
  
button {
    margin: 0;
  }

h1 {
    margin: 0;
    padding-top: 1%;
  }
  
.dark-mode {
    background-color: black;
    color: white;
    min-height: 100vh; /* Set the height to 100% of the viewport height */
  }
  
.dark-mode .minute {
    width: 11px;
    height: 11px;
    background-color: rgba(211, 211, 211, 0.089);
    margin: 1px;
    border-radius:1px;
    
  }
  
.dark-mode .minute.working {
    background-color: green;
  }

  * {
    
  }